import React from "react";
import Page from "@jumbo/shared/Page";
import Dashboard from "../pages/admin/Dashboard";
import Programs from "../pages/admin/Programs";
import Profile from "../pages/admin/Profile";
import Students from "../pages/admin/Students";
import Parents from "../pages/admin/Parents";
import Teachers from "../pages/admin/Teachers";
import Plans from "../pages/admin/Plans";
import Calendar from "../pages/admin/Calendar";
import Promotions from "../pages/admin/Promotions";

const adminroutes = [
    {
        path: "/dashboard",
        element: <Page component={Dashboard}/>,
    },
    {
        path: [
            "/dashboard/students",
            "/dashboard/students/:category",
            "/dashboard/students/:category/:id",
        ],
        element: <Page component={Students}/>,
    },
    {
        path: [
            "/dashboard/programs",
            "/dashboard/programs/:category",
            "/dashboard/programs/:category/:id",
        ],
        element: <Page component={Programs}/>,
    },
    {
        path: [
            "/dashboard/calendar",
        ],
        element: <Page component={Calendar}/>,
    },
    {
        path: [
            "/dashboard/plans",
            "/dashboard/plans/:category",
            "/dashboard/plans/:category/:id",
        ],
        element: <Page component={Plans}/>,
    },
    {
        path: [
            "/dashboard/teachers",
            "/dashboard/teachers/:category",
            "/dashboard/teachers/:category/:id",
        ],
        element: <Page component={Teachers}/>,
    },
    {
        path: [
            "/dashboard/parents",
            "/dashboard/parents/:category",
            "/dashboard/parents/:category/:id",
        ],
        element: <Page component={Parents}/>,
    },
    {
        path: "/dashboard/profile",
        element: <Page component={Profile}/>,
    },
    {
        path:"/dashboard/promotions",
        element: <Page component={Promotions}/>,
    },
];

export default adminroutes;
